import { getProductPageUrlSync } from '@msdyn365-commerce-modules/retail-actions';
import { format, getPayloadObject, getTelemetryAttributes, ITelemetryContent, onTelemetryClick } from '@msdyn365-commerce-modules/utilities';
import { AddToWishlistComponent,PriceComponent} from '@msdyn365-commerce/components';
import { IComponent, IComponentProps, ICoreContext, IGridSettings, IImageData, IImageSettings, Image, msdyn365Commerce } from '@msdyn365-commerce/core';
import { ProductPrice, ProductSearchResult } from '@msdyn365-commerce/retail-proxy';
import React from 'react';
// import { addCouponsAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/CartsDataActions.g';
import Card from 'reactstrap/lib/Card';

export interface IProductComponentProps extends IComponentProps<{ product?: ProductSearchResult }> {
    className?: string;
    imageSettings?: IImageSettings;
    savingsText?: string;
    freePriceText?: string;
    originalPriceText?: string;
    currentPriceText?: string;
    ratingAriaLabel?: string;
    allowBack?: boolean;
    telemetryContent?: ITelemetryContent;
}

export interface IProductComponent extends IComponent<IProductComponentProps> { }

const PriceComponentActions = {
};

const ProductCard: React.FC<IProductComponentProps> = ({
    data,
    context,
    imageSettings,
    savingsText,
    freePriceText,
    originalPriceText,
    currentPriceText,
    ratingAriaLabel,
    allowBack,
    typeName,
    id,
    telemetryContent
}) => {

    // const [size,setSize] = useState("");
    const product = data.product;
    if (!product) {
        return null;
    }

    // function setSizeVal(value:any) {
    //     setSize(value);
    //     console.log("clicked size",size,"data",data);
    // }

    let productUrl = getProductPageUrlSync(product.Name || '', product.RecordId, context && context.actionContext, undefined);
    if(allowBack) {
        productUrl = updateProductUrl(productUrl, context);
    }

    // Construct telemetry attribute to render
    const payLoad = getPayloadObject('click', telemetryContent!, '', product.RecordId.toString());

    const attribute = getTelemetryAttributes(telemetryContent!, payLoad);

    function sizeMapper(): ('' | JSX.Element[] | undefined)[] | undefined {
        return product && product.AttributeValues && product.AttributeValues.map(item => {
            if(item.Name === 'Size') {
                  return item && item.TextValue && item.TextValue.split('|').map((itemData,index) => {
                    return(
                         <li key={index} > <a href={`${productUrl}`}>{itemData} </a>  </li>
                    );
                });

            }
          return;
        });
    }

    return (
        <>
            <div className='msc-product__image container'>

            {renderProductPlacementImage(imageSettings, context.request.gridSettings, product.PrimaryImageUrl, product.Name)}
             <div className='centered'>
                <h4> Sizes </h4>
                <ul className='my-row'>
                {sizeMapper()}
                </ul>
            </div>

            </div>
            <div className='msc-product__details'>
                {renderPrice(context, typeName, id, product.BasePrice, product.Price, savingsText, freePriceText, originalPriceText, currentPriceText)}
                <a
                    href={productUrl}
                    onClick={onTelemetryClick(telemetryContent!, payLoad, product.Name!)}
                    aria-label={renderLabel(product.Name, context.cultureFormatter.formatCurrency(product.Price), product.AverageRating, ratingAriaLabel)}
                    className='msc-product'
                    {...attribute}
                >
                    <h4 className='msc-product__title'>{product.Name}</h4>
                </a>
                {renderDescription(product.Description)}
                {/* {!context.app.config.hideRating && renderRating(context, typeName, id, product.AverageRating, product.TotalRatings, ratingAriaLabel)} */}
                <div className='wishlist-btn'>
                <AddToWishlistComponent
                    className='msc-wishlist-icon msc-btn'
                    nameOfWishlist='plp'
                    addToWishlistButtonText='add'
                    removeFromWishlistButtonText='remove'
                    context={context}
                    id={id}
                    data={{product:{ProductTypeValue:Number(id),AdjustedPrice:product.Price,RecordId:Number(id),BasePrice:product.Price,Price:product.Price}}}
                    typeName={typeName}
                />

                    <Card>
                    <h3> Favourites </h3>
                    <p>
                        SAVE YOUR FAVORITE ITEMS
                        Want to save the items that you love?
                        Just click on the heart symbol beside
                        the item and it will show up here.
                    </p>
                    <button className='btn-primary'> Browse Now  </button>
                    </Card>

                </div>
            </div>

    </>

    );
};

function renderLabel(name?: string, price?: string, rating?: number, ratingAriaLabel?: string): string {
    name = name || '';
    price = price || '';
    return (`${name} ${price} ${getRatingAriaLabel(rating, ratingAriaLabel)}`);
}

function renderDescription(description?: string): JSX.Element | null {
    return <p className='msc-product__text'>{description}</p>;
}

function getRatingAriaLabel(rating?: number, ratingAriaLabel?: string): string {
    if (rating && ratingAriaLabel) {
    const roundedRating = rating.toFixed(2);
    return format(ratingAriaLabel || '', roundedRating, '5');
    }
    return '';
}

function updateProductUrl(productUrl: string, context:ICoreContext): string {
    const srcUrl = new URL(productUrl, context.request.apiSettings.baseUrl);
    const queryString = `back=true` ;
    if (srcUrl.search) {
            srcUrl.search += `&${queryString}`;
        } else {
            srcUrl.search += queryString;
        }

    const updatedUrl = new URL(srcUrl.href);
    return updatedUrl.pathname + srcUrl.search;
}

// function renderRating(context: ICoreContext, typeName: string, id: string, avgRating?: number, totalRatings?: number, ariaLabel?: string): JSX.Element | null {
//     if (!avgRating) {
//         return null;
//     }

//     const numRatings = totalRatings && totalRatings.toString() || undefined;
//     const ratingAriaLabel = getRatingAriaLabel(avgRating, ariaLabel);

//     return (
//         <RatingComponent
//             context={context}
//             id={id}
//             typeName={typeName}
//             avgRating={avgRating}
//             ratingCount={numRatings}
//             readOnly={true}
//             ariaLabel={ratingAriaLabel}
//             data={{}}
//         />
//     );
// }

function renderPrice(context: ICoreContext, typeName: string, id: string, basePrice?: number, adjustedPrice?: number, savingsText?: string, freePriceText?: string, originalPriceText?: string, currentPriceText?: string): JSX.Element | null {
    const price: ProductPrice = {
        BasePrice: basePrice,
        AdjustedPrice: adjustedPrice,
        CustomerContextualPrice: adjustedPrice
    };

    return (
        <PriceComponent
            context={context}
            id={id}
            typeName={typeName}
            data={{ price: price }}
            savingsText={savingsText}
            freePriceText={freePriceText}
            originalPriceText={originalPriceText}
        />
    );
}

function renderProductPlacementImage(imageSettings?: IImageSettings, gridSettings?: IGridSettings, imageUrl?: string, altText?: string): JSX.Element | null {
    if (!imageUrl || !gridSettings || !imageSettings) {
        return null;
    }
    const img: IImageData = {
        src: imageUrl,
        altText: altText ? altText : ''
    };
    const imageProps = {
        gridSettings: gridSettings,
        imageSettings: imageSettings
    };
    imageProps.imageSettings.cropFocalRegion = true;
    return (
        <Image {...img} {...imageProps} loadFailureBehavior='empty' />
    );
}

// @ts-ignore
export const ProductComponent: React.FunctionComponent<IProductComponentProps> = msdyn365Commerce.createComponentOverride<IProductComponent>(
    'Product',
    { component: ProductCard, ...PriceComponentActions }
);

export default ProductComponent;